import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { configuration } from '../../common/configuration';
import { Token, TokenSet } from '../models/tokenSet.model';
import { StorageService } from '../../storage';
import jwt_decode from 'jwt-decode';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  refreshTokenTimeout;
  loginFromAppointment: boolean;
  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService,
    private router: Router
  ) {}

  public getUser(): Token | null {
    const accessToken: string = this.storageService.getItem('access') || null;
    if (accessToken) {
      return jwt_decode(accessToken);
    }
    return null;
  }
  public getUserGroup(): string[] | null {
    const accessToken: string = this.storageService.getItem('access') || null;
    if (accessToken) {
      const decodedToken: Token = jwt_decode(accessToken);
      return decodedToken.groups;
    }
    return null;
  }
  public isAdmin(): boolean {
    const accessToken: string = this.storageService.getItem('access') || null;
    if (accessToken) {
      const decodedToken: Token = jwt_decode(accessToken);
      const userGroups = decodedToken.groups;
      return userGroups.indexOf('admin') > -1;
    }
    return false;
  }
  public logout(): void {
    this.storageService.clear();
    this.stopRefreshToken();
    this.router.navigate(['/login']);
  }
  public canActivate(): boolean {
    return this.getUser() !== null;
  }

  public getAuthToken(): string {
    return this.storageService.getItem('access') || '';
  }
  public startSilentRefreshing(): void {
    const token = jwt_decode<Token>(this.storageService.getItem('access'));
    const expires = new Date(token.exp * 1000);
    const timeout = expires.getTime() - Date.now() - 60 * 1000;
    this.refreshTokenTimeout = setTimeout(() => {
      this.refresh();
    }, timeout);
  }

  private stopRefreshToken(): void {
    clearTimeout(this.refreshTokenTimeout);
  }
  public refresh(): any {
    this.httpClient
      .post<TokenSet>(`${configuration.apiUrl}/login/refresh/`, {
        refresh: this.storageService.getItem('refresh'),
      })
      .subscribe(
        (res) => {
          this.storageService.setItem('access', res.access);
        },
        (error) => {
          this.storageService.clear();
          this.stopRefreshToken();
          this.router.navigate(['/login']);
        }
      );
  }
  passwordReset(email): Observable<any> {
    return this.httpClient.post(
      `${configuration.apiUrl}/password_reset/`,
      email
    );
  }
  confirmPasswordChange(data): Observable<any> {
    return this.httpClient.post(
      `${configuration.apiUrl}/password_reset/confirm/`,
      data
    );
  }
}
