import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(public auth: AuthService, public router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const userGroups = [];
    if (!this.auth.canActivate()) {
      this.router.navigate(['/login']);
      return false;
    } else {
      const user = this.auth.getUser();
      user.groups.forEach((group) => {
        userGroups.push(group);
      });
      if (route.data.roles && userGroups.length !== 0) {
        const roles = route.data.roles;
        if (roles.indexOf(userGroups[0]) === -1) {
          this.router.navigate(['/dashboard']);
          return false;
        }
      }
    }
    return true;
  }
}
