import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from 'projects/shared/src/lib/authentication/services/authGuard.service';

// Import Containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { GroupsEnum } from '../../../shared/src/lib/authentication/models/groups-enum.model';

export const routes: Routes = [
  {
    path: '404',
    component: P404Component,
    data: {
      title: 'Page 404',
    },
  },
  {
    path: '500',
    component: P500Component,
    data: {
      title: 'Page 500',
    },
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home',
    },
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./views/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'notifications',
        loadChildren: () =>
          import('./views/notifications/notifications.module').then(
            (m) => m.NotificationsModule
          ),
      },
      {
        path: 'widgets',
        loadChildren: () =>
          import('./views/widgets/widgets.module').then((m) => m.WidgetsModule),
      },
      {
        path: 'service',
        loadChildren: () =>
          import('./views/service/service.module').then((m) => m.ServiceModule),
        canActivate: [AuthGuardService],
        data: {
          roles: [
            GroupsEnum.admin,
            GroupsEnum.reception_employee,
            GroupsEnum.doctor,
          ],
        },
      },
      {
        path: 'register',
        loadChildren: () =>
          import('./views/register/register.module').then(
            (m) => m.RegisterModule
          ),
      },
      {
        path: 'login',
        loadChildren: () =>
          import('./views/login/login.module').then((m) => m.LoginModule),
      },
      {
        path: 'doctors',
        loadChildren: () =>
          import('./views/doctors/doctors.module').then((m) => m.DoctorsModule),
        canActivate: [AuthGuardService],
        data: {
          roles: [
            GroupsEnum.admin,
            GroupsEnum.reception_employee,
            GroupsEnum.doctor,
          ],
        },
      },
      {
        path: 'appointment-terms',
        loadChildren: () =>
          import('./views/appointment-terms/appointment-terms.module').then(
            (m) => m.AppointmentTermsModule
          ),
        canActivate: [AuthGuardService],
        data: {
          roles: [
            GroupsEnum.admin,
            GroupsEnum.reception_employee,
            GroupsEnum.doctor,
          ],
        },
      },
      {
        path: 'patients',
        loadChildren: () =>
          import('./views/patient/patient.module').then((m) => m.PatientModule),
        canActivate: [AuthGuardService],
      },
      {
        path: 'patient-appointments',
        loadChildren: () =>
          import(
            './views/patient-appointments/patient-appointments.module'
          ).then((m) => m.PatientAppointmentsModule),
      },
      {
        path: 'prescriptions',
        loadChildren: () =>
          import('./views/prescriptions/prescriptions.module').then(
            (m) => m.PrescriptionsModule
          ),
        // canActivate: [AuthGuardService]
      },
    ],
  },
  { path: '**', component: P404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
