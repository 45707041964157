import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'fas fa-tachometer-alt',
  },
  {
    name: 'Usługi',
    url: 'service',
    icon: 'cil-clipboard',
  },
  {
    name: 'Lekarze',
    url: 'doctors',
    icon: 'fas fa-stethoscope',
  },
  {
    name: 'Pacjenci',
    url: 'patients',
    icon: 'icon-people',
  },
  {
    name: 'Terminy wizyt',
    url: 'appointment-terms',
    icon: 'icon-calendar',
  },
  {
    name: 'Recepty',
    url: 'prescriptions',
    icon: 'far fa-calendar-alt',
  },
];
export const navItemsPatient: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'fas fa-tachometer-alt',
  },
  {
    name: 'Umów wizytę',
    url: 'patient-appointments',
    icon: 'far fa-calendar-plus',
  },
  {
    name: 'Moje wizyty',
    url: 'patients/:id/history',
    icon: 'far fa-calendar-alt',
  },
  {
    name: 'Recepty',
    url: 'prescriptions',
    icon: 'far fa-calendar-alt',
  },
];
export const navItemNotLogged: INavData[] = [
  {
    name: 'Umów wizytę',
    url: 'patient-appointments',
    icon: 'far fa-calendar-plus',
  },
];
