<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
</ngx-spinner>
<app-alert></app-alert>
<app-header
  [navbarBrandRouterLink]="['/dashboard']"
  [fixed]="true"
  [navbarBrand]="{src: 'assets/img/brand/CD-TChRz LOGO.png', alt: 'Potrafie Pomoc logo'}"
  [sidebarToggler]="'lg'"
  [asideMenuToggler]="false"
  *ngIf="!loginPage">

<!--  <a class="dropdown-item" (click)="settings = !settings"><i class="cil-settings"></i> ustawienia</a>-->
<!--  <a class="dropdown-item" *ngIf="settings" (click)="changeLang('pl')">PL</a>-->
<!--  <a class="dropdown-item" *ngIf="settings" (click)="changeLang('en')">EN</a>-->

  <div class="dropdowns-wrapper">
    <ul class="nav navbar-nav ml-auto">
      <li class="nav-item dropdown" dropdown placement="bottom right" >
        <a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"
           dropdownToggle (click)="false">
          <i class="cil-settings"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
          <a class="dropdown-item">
            {{ 'APPLICATION.CONFIGURATION.read' | translate}}
            <i class="fas fa-volume-up" (click)="voiceOn(false)" *ngIf="!textReader"></i>
            <i class="fas fa-volume-mute" (click)="voiceOn(true)" *ngIf="textReader"></i>
          </a>
            <a class="dropdown-item" (click)="changeLang('pl')">
              <span class="flag-icon flag-icon-pl"></span> PL
            </a>
            <a class="dropdown-item" (click)="changeLang('en')">
              <span class="flag-icon flag-icon-gb"></span> EN
            </a>
        </div>
      </li>
    </ul>

    <ul class="nav navbar-nav ml-auto">
      <li class="nav-item dropdown" dropdown placement="bottom right" >
        <a class="nav-link" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"
           dropdownToggle (click)="false">
          <i class="fa fa-user"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
          <a class="dropdown-item" *ngIf="loggedUser && this.userRole.indexOf('patient') > -1" (click)="navigateToProfile()"><i
                  class="fa fa-user"></i><span> {{ 'APPLICATION.PATIENTS.profile' | translate }}</span></a>
          <a class="dropdown-item" *ngIf="!loggedUser" routerLink="login"><i
                  class="cil-account-login"></i><span> {{ 'APPLICATION.LOGIN.loginButton' | translate }}</span></a>
          <a class="dropdown-item" *ngIf="loggedUser" (click)="logout()"><i
                  class="cil-account-logout"></i><span> {{ 'APPLICATION.LOGIN.logoutButton' | translate }}</span></a>
        </div>
      </li>
    </ul>
  </div>
</app-header>
<div class="app-body">
  <app-sidebar #appSidebar [fixed]="true" [display]="'lg'" [minimized]="sidebarMinimized"
               (minimizedChange)="toggleMinimize($event)" *ngIf='loggedUser != null'>
    <app-sidebar-nav [navItems]="navItems" [perfectScrollbar] [disabled]="appSidebar.minimized"></app-sidebar-nav>
    <app-sidebar-minimizer></app-sidebar-minimizer>
  </app-sidebar>
  <main class="main">
    <app-breadcrumbs *ngIf="!loginPage"></app-breadcrumbs>
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
  </main>
</div>
<div [ngClass]="(isPatient) ? 'hide-messenger' : ''">
  <div class="fb-customerchat"
       [attr.page_id]="facebookPageId"
       theme_color="#83BD3C">
  </div>
</div>
<app-footer *ngIf="!loginPage">
  <span>Potrafię Pomóc &copy; 2021</span>
</app-footer>
