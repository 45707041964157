<form [formGroup]="checkoutForm" (ngSubmit)="onSubmit()">
    <div *ngFor="let filter of filters" class="form-group col-xs-{{filter.size}} col-md-{{filter.size}}">
      <label for="filter.name" class="control-label">{{ filter.label}}</label>
      <input *ngIf="filter.inputType == 'text' || filter.inputType == 'number'" id="{{filter.name}}" type="filter.inputType"  placeholder="{{filter.placeholder}}" formControlName="{{filter.name}}">
      <select *ngIf="filter.inputType == 'select'" id="{{filter.name}}" type="filter.inputType"  placeholder="{{filter.placeholder}}" formControlName="{{filter.name}}">
        <option *ngFor="let option of filter.options">{{ option }}</option>
      </select>
    </div>
    <button class="button btn btn-secondary" type="submit" (click)="onSubmit()">Szukaj</button>
</form>
