import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { Filter } from 'shared';

@Component({
	selector: 'app-filter',
	templateUrl: './filter.component.html',
	styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {
	@Input() filters: Filter[];
	@Output() search = new EventEmitter<any>();
	checkoutForm = this.formBuilder.group({
		serviceName: '',
		serviceType: '',
		priceFrom: '',
		priceTo: ''
	});
	constructor( private formBuilder: FormBuilder ) {}

	ngOnInit(): void {
	}
	onSubmit(): void {
		this.search.emit(this.checkoutForm);
	}
}

