import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../../../../../../shared/src/lib/authentication/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  newPassword: string;
  confirmPassword: string;
  token: string;
  passwordChanged: boolean;
  @ViewChild('newPasswordInput') newPasswordInput: ElementRef;
  @ViewChild('confirmPasswordInput') confirmPasswordInput: ElementRef;
  passwordShow: boolean;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      /* tslint:disable:no-string-literal */
      this.token = params['id'];
    });
  }

  checkPasswords(): boolean {
    if (this.newPassword) {
      return this.newPassword === this.confirmPassword;
    }
  }

  changePassword(): void {
    const data = {
      token: this.token,
      password: this.newPassword,
    };
    this.authService.confirmPasswordChange(data).subscribe((res) => {
      this.passwordChanged = true;
    });
  }

  showPassword(): void {
    this.passwordShow = !this.passwordShow;
    this.newPasswordInput.nativeElement.type = this.passwordShow
      ? 'text'
      : 'password';
    this.confirmPasswordInput.nativeElement.type = this.passwordShow
      ? 'text'
      : 'password';
  }
}
