<div class="breadcrumbs-wrapper">
    <ol class="breadcrumb">
        <li *ngFor="let breadcrumb of breadcrumbs; let i = index;">
            <span *ngIf="!breadcrumbsLength(i)" [routerLink]="breadcrumb.url" routerLinkActive="router-link-active">
              {{ breadcrumb.label | translate}}
            </span>
            <span *ngIf="breadcrumbsLength(i)">
              {{ breadcrumb.label | translate}}
            </span>
        </li>
    </ol>
</div>
