import { Injectable } from '@angular/core';
import { SharedModule } from '../../shared.module';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TokenSet } from '../../authentication/models/tokenSet.model';
import { StorageService } from '../../storage/storage.service';
import { configuration } from '../../common/configuration';
import { AppointmentTerm } from '../../appointments-term/models/appointment-term.model';

@Injectable({
  providedIn: 'root',
})
export class LoginProxy {
  loginFromAppointment: boolean;
  goBackTo: AppointmentTerm;

  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService
  ) {}

  public async loginPatient(
    username: string,
    password?: string
  ): Promise<boolean> {
    const tokens = await this.httpClient
      .post<TokenSet>(`${configuration.apiUrl}/login/`, {
        username,
        password,
      })
      .toPromise();
    if (tokens) {
      this.storageService.setItem('access', tokens.access);
      this.storageService.setItem('refresh', tokens.refresh);
      return true;
    }
    return false;
  }
}
