<div class="app-body">
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-md-8 mx-auto">
          <div class="card-group">
            <div class="card p-4" >
              <div class="card-body" *ngIf="!passwordResetModal">
                <form [formGroup]="loginForm" (ngSubmit)="login()">
                  <h1>{{ 'APPLICATION.LOGIN.login' | translate }}</h1>
                  <div class="input-group mb-3">
                    <label>{{ 'APPLICATION.LOGIN.email' | translate }}
                      <input
                      type="text"
                      class="form-control"
                      placeholder="{{ 'APPLICATION.LOGIN.email' | translate }}"
                      formControlName="username"
                      required>
                    </label>
                    <div *ngIf="username.invalid && (username.dirty || username.touched)"
                    class="error-msg">
                      <div *ngIf="username.errors.required">
                        {{ 'APPLICATION.REGISTER.requiredFieldError' | translate }}
                      </div>
                    </div>
                    <div *ngIf="loginError"
                    class="error-msg">
                      <div>
                        {{ 'APPLICATION.LOGIN.loginError' | translate }}
                      </div>
                    </div>
                  </div>
                  <div class="input-group mb-4">
                    <label>{{ 'APPLICATION.LOGIN.password' | translate }}
                      <input
                      type="password"
                      class="form-control"
                      placeholder="{{ 'APPLICATION.LOGIN.password' | translate }}"
                      formControlName="password"
                      required>
                    </label>
                    <div *ngIf="password.invalid && (password.dirty || password.touched)"
                    class="error-msg">
                      <div *ngIf="password.errors.required">
                        {{ 'APPLICATION.REGISTER.requiredFieldError' | translate }}
                      </div>
                    </div>
                    <div *ngIf="loginError"
                    class="error-msg">
                      <div>
                        {{ 'APPLICATION.LOGIN.loginError' | translate }}
                      </div>
                    </div>
                  </div>
                  <div class="row login-btns-wrapper">
                    <div>
                      <button type="button" *ngIf="!loginForm.valid" class="btn btn-primary px-4 login-btn" (click)="submitInvalidForm()">{{ 'APPLICATION.LOGIN.loginButton' | translate }}</button>
                      <button type="submit" *ngIf="loginForm.valid" class="btn btn-primary px-4 login-btn">{{ 'APPLICATION.LOGIN.loginButton' | translate }}</button>
                    </div>
                    <div class="login-other-btns-wrapper">
                      <div>
                        <button type="button" class="btn btn-link px-0" id="password_reset-link-btn" (click)="passwordResetModal = !passwordResetModal">{{ 'APPLICATION.LOGIN.dontRememberPassword' | translate }}</button>
                      </div>
                      <div>
                        <button type="button" class="btn btn-link px-0" id="register-link-btn" [routerLink]="['/register']">{{ 'APPLICATION.LOGIN.register' | translate }}</button>
                      </div>
                      <div>
                        <button type="button" class="btn btn-link px-0" (click)="loginEmployee()">{{ 'APPLICATION.LOGIN.employee' | translate }}</button>
                      </div>
                      <div>
                        <button type="button" class="btn btn-link px-0" routerLink="/dashboard"><i class="fas fa-step-backward"></i> {{ 'APPLICATION.SERVICES.back' | translate }}</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="card-body card-body-reset-password" *ngIf="passwordResetModal">
                <h1>{{ 'APPLICATION.LOGIN.passwordReset' | translate }}</h1>
                <label *ngIf="!resetEmailSended">{{ 'APPLICATION.LOGIN.email' | translate }}
                  <input
                          type="email"
                          class="form-control"
                          placeholder="{{ 'APPLICATION.LOGIN.email' | translate }}"
                          [(ngModel)]="emailReset"
                          required>
                </label>
                <div *ngIf="!resetEmailSended">
                  <button type="button" class="btn btn-primary px-4 login-btn" [disabled]="!checkIsEmail()" (click)="passwordReset()">{{ 'APPLICATION.LOGIN.send' | translate }}</button>
                </div>
                <span class="reset-password-span" *ngIf="resetEmailSended">
                  {{ 'APPLICATION.LOGIN.passwordResetMessage' | translate }}
                </span>
                <div class="reset-password-back-btn">
                  <button type="button" class="btn btn-link px-0" (click)="passwordResetModal = !passwordResetModal"><i class="fas fa-step-backward"></i> {{ 'APPLICATION.SERVICES.back' | translate }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>
