import { Injectable } from '@angular/core';
import { JsonStorageTranscoder } from './storage-transcoders';

// @dynamic
@Injectable({
  providedIn: 'root',
})
export class StorageService {
  public has(key: string): boolean {
    return StorageService.storage.getItem(key) !== null;
  }

  public remove(key: string): void {
    StorageService.storage.removeItem(key);
  }

  public clear(): void {
    StorageService.storage.clear();
  }

  public getItem<T>(key: string): T | undefined {
    const value = StorageService.storage.getItem(key);
    return value != null ? JsonStorageTranscoder.decode(value) : undefined;
  }

  public setItem<T>(key: string, value: T): void {
    const stringValue = JsonStorageTranscoder.encode(value);
    return StorageService.storage.setItem(key, stringValue);
  }

  private static get storage(): Storage {
    return window.localStorage;
  }
}
