<div class="app-body">
    <main class="main d-flex align-items-center">
        <div class="container">
            <div class="row">
                <div class="col-md-8 mx-auto">
                    <div class="card-group">
                        <div class="card p-4">
                            <div class="card-body card-body-reset-password" *ngIf="!passwordChanged">
                                <h1>{{ 'APPLICATION.LOGIN.passwordReset' | translate }}</h1>
                                <label>{{ 'APPLICATION.LOGIN.password' | translate }}
                                    <input
                                            type="password"
                                            class="form-control"
                                            placeholder="{{ 'APPLICATION.LOGIN.password' | translate }}"
                                            [(ngModel)]="newPassword"
                                            #newPasswordInput
                                            required>
                                    <div class="showPassword-wrapper" (click)="showPassword()">
                                        <i class="far fa-eye" *ngIf="!passwordShow"></i>
                                        <i class="far fa-eye-slash" *ngIf="passwordShow"></i>
                                    </div>
                                </label>
                                <label>{{ 'APPLICATION.LOGIN.confirmPassword' | translate }}
                                    <input
                                            type="password"
                                            class="form-control"
                                            placeholder="{{ 'APPLICATION.LOGIN.confirmPassword' | translate }}"
                                            [(ngModel)]="confirmPassword"
                                            #confirmPasswordInput
                                            required>
                                    <div class="showPassword-wrapper" (click)="showPassword()">
                                        <i class="far fa-eye" *ngIf="!passwordShow"></i>
                                        <i class="far fa-eye-slash" *ngIf="passwordShow"></i>
                                    </div>
                                </label>
                                <div>
                                    <button type="button" class="btn btn-primary px-4 login-btn" [disabled]="!checkPasswords()" (click)="changePassword()">{{ 'APPLICATION.LOGIN.send' | translate }}</button>
                                </div>
                            </div>
                            <div class="card-body password-change-success" *ngIf="passwordChanged">
                                <span class="password-change-success-span">
                                    {{ 'APPLICATION.LOGIN.passwordChangeSuccess' | translate }}
                                </span>
                                <div>
                                    <button type="button" class="btn btn-link px-0" id="register-link-btn" [routerLink]="['/login']">{{ 'APPLICATION.REGISTER.goToLoginPage' | translate }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>
