import { Component, OnInit } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { INavData } from '@coreui/angular';
import {
  Token,
  AuthService,
  LoginProxy,
  configuration,
  VoiceRecognitionService,
} from 'shared';
import { navItemNotLogged, navItems, navItemsPatient } from '../../_nav';
import { TranslateService } from '@ngx-translate/core';
import { FacebookService, InitParams } from 'ngx-facebook';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
})
export class DefaultLayoutComponent implements OnInit {
  loaded = false;
  public sidebarMinimized = false;
  public navItems: INavData[];
  loginPage: boolean;
  loggedUser: Token;
  userRole: string[];
  textReader = true;
  breadcrumbs = [];

  isPatient = false;

  facebookPageId = configuration.facebookPageId;

  constructor(
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private loginService: LoginProxy,
    private facebookService: FacebookService,
    private voiceService: VoiceRecognitionService
  ) {}

  toggleMinimize(e): void {
    this.sidebarMinimized = e;
  }

  ngOnInit(): void {
    this.loginPage =
      this.router.url === '/login' ||
      this.router.url === '/register' ||
      this.router.url.includes('/login');
    this.loggedUser = this.authService.getUser();
    this.userRole = this.authService.getUserGroup();
    this.getNavData();
    if (this.loggedUser) {
      this.authService.startSilentRefreshing();
    } else {
      if (!this.loginPage) {
        this.router.navigate(['/patient-appointments']);
      }
    }
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
      this.userRole = this.authService.getUserGroup();
      this.getNavData();
      if (this.authService.loginFromAppointment && this.loginService.goBackTo) {
        this.router.navigate(['/patient-appointments']);
      }
      this.loginPage =
        evt.url === '/login' ||
        evt.url === '/register' ||
        evt.url.includes('/login');
    });
    // this.initFacebookService();
    this.loaded = true;
  }
  private initFacebookService(): void {
    const initParams: InitParams = { xfbml: true, version: 'v3.2' };
    this.facebookService.init(initParams);
  }

  logout(): void {
    this.authService.logout();
  }

  navigateToProfile(): void {
    this.router.navigate(['patients', this.loggedUser.patient_id]);
  }

  getNavData(): void {
    this.isPatient = false;
    this.loggedUser = this.authService.getUser();
    if (!this.loggedUser) {
      this.navItems = navItemNotLogged;
    } else {
      if (this.userRole && this.userRole.indexOf('patient') > -1) {
        this.navItems = navItemsPatient;
        this.navItems[2].url = `patients/${this.loggedUser.patient_id}/history`;
        this.isPatient = true;
      } else {
        this.navItems = navItems;
      }
    }
  }
  changeLang(lang: string): void {
    this.translate.setDefaultLang(lang);
    this.translate.use(lang);
  }
  voiceOn(on: boolean): void {
    this.textReader = !on;
    this.voiceService.voiceOn.emit(on);
  }
}
