import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  InteractionStatus,
  InteractionType,
  PopupRequest,
  RedirectRequest,
} from '@azure/msal-browser';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LoginProxy } from 'projects/shared/src/lib/login/services/login-proxy.service';
import { StorageService } from 'projects/shared/src/lib/storage';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import jwt_decode from 'jwt-decode';
import { TokenMicrosoft } from 'projects/shared/src/lib/authentication/models/tokenSet.model';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'shared';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  isIframe = false;
  loginDisplay = false;
  loginError = false;
  passwordResetModal = false;
  // tslint:disable-next-line: variable-name
  private readonly _destroying$ = new Subject<void>();
  emailReset: string;
  resetEmailSended: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private service: LoginProxy,
    private router: Router,
    private storageService: StorageService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private titleService: Title,
    private authencticationService: AuthService,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private spinner: NgxSpinnerService
  ) {
    /* tslint:disable:no-string-literal */
    translate
      .get(this.route.snapshot.data['title'])
      .subscribe((res: string) => {
        this.titleService.setTitle(res);
      });
  }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      username: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required]),
    });
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });
    if (this.storageService.getItem('access')) {
      this.router.navigate(['/dashboard']);
    }
  }

  submitInvalidForm(): void {
    this.loginForm.markAllAsTouched();
  }

  login(): void {
    this.spinner.show();
    const username = this.username.value;
    const password = this.password.value;
    this.service.loginPatient(username, password).then(
      (result) => {
        if (result) {
          this.authencticationService.loginFromAppointment = true;
          this.spinner.hide();
          this.router.navigate(['/dashboard']);
        }
      },
      (error) => {
        this.spinner.hide();
        this.loginError = true;
      }
    );
  }

  setLoginDisplay(): void {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount(): void {
    const activeAccount = this.authService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  loginEmployee(): void {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService
          .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            const userEmail = jwt_decode<TokenMicrosoft>(response.idToken)
              .preferred_username;
            this.service.loginPatient(userEmail).then((result) => {
              if (result) {
                this.router.navigate(['/dashboard']);
              }
            });
            this.authService.instance.setActiveAccount(response.account);
          });
      } else {
        this.authService
          .loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
        } as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }
    }
  }

  logout(): void {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: '/',
      });
    } else {
      this.authService.logoutRedirect();
    }
  }

  passwordReset(): void {
    const email = {
      email: this.emailReset,
    };
    this.authencticationService.passwordReset(email).subscribe((res) => {
      this.resetEmailSended = true;
    });
  }

  checkIsEmail(): boolean {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(this.emailReset);
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  get username(): any {
    return this.loginForm.get('username');
  }

  get password(): any {
    return this.loginForm.get('password');
  }
}
