import { Injectable, OnInit } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AlertService } from '../utilities/alerts/alerts/alerts.service';
import { Router } from '@angular/router';
import { AuthService } from '../authentication/services/auth.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({ providedIn: 'root' })
export class HttpErrorInterceptor implements HttpInterceptor {
  msg303 = 'Ten adres e-mail jest już używany.';
  msg401 = 'Podany adres nie istnieje w naszej bazie danych.';
  msg403 = 'Niestety nie posiadasz uprawnień do tej zawartości.';
  msg408 =
    'Niestety przekroczono limit czasu oczekawiania na żadanie przeglądarki.';
  msgErr = 'Niestety, coś poszło nie tak.';

  constructor(
    private spinner: NgxSpinnerService,
    private alertService: AlertService,
    private router: Router,
    private authService: AuthService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        let errorMessage = '';
        if (error.error instanceof ErrorEvent) {
          // client-side error
          errorMessage = this.msgErr;
        } else {
          // server-side error
          if (error.status === 303) {
            errorMessage = this.msg303;
          } else if (error.status === 401) {
            errorMessage = this.msg401;
            this.authService.logout();
          } else if (error.status === 403) {
            errorMessage = this.msg403;
            this.authService.logout();
          } else if (error.status === 408) {
            errorMessage = this.msg408;
          } else {
            errorMessage = this.msgErr;
          }
        }
        this.spinner.hide();
        this.alertService.error(errorMessage, {
          autoClose: true,
          keepAfterRouteChange: true,
        });
        return throwError(errorMessage);
      })
    );
  }
}
