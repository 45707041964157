import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { SharedComponent } from './shared.component';
import { HttpErrorInterceptor } from './errorInerceptor/http-error.interceptor';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

@NgModule({
  declarations: [SharedComponent],
  imports: [HttpClientModule, TranslateModule],
  exports: [SharedComponent],
  providers: [
    HttpClientModule,
    TranslateService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
  ],
})
export class SharedModule {}

export function createTranslateLoader(http): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
