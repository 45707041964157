import { Component, HostListener, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { VoiceRecognitionService } from 'shared';
import { IconSetService } from '@coreui/icons-angular';
import { freeSet } from '@coreui/icons';
import { TranslateService } from '@ngx-translate/core';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>',
  providers: [IconSetService],
})
export class AppComponent implements OnInit {
  voiceOn: boolean;
  constructor(
    private router: Router,
    public iconSet: IconSetService,
    private translate: TranslateService,
    private voiceService: VoiceRecognitionService
  ) {
    iconSet.icons = { ...freeSet };
    translate.setDefaultLang('pl');
    translate.use('pl');
  }

  ngOnInit(): void {
    this.voiceService.voiceOn.subscribe((voiceOn) => {
      this.voiceOn = voiceOn;
    });
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
  @HostListener('window:click', ['$event'])
  keyEvent(event): void {
    if (event.target.children.length === 0 && this.voiceOn) {
      this.voiceService.html = event.target.innerHTML;
      this.voiceService.start();
    }
  }
}
